<template>
    <div>
        <h4 class="mb-1">Клиенты</h4>
        <div class="mb-2">
            <b-input placeholder="Поиск клиента"
                     v-model="search"
                     trim
                     size="sm"
                     @input="searchDebounce"
            />
        </div>

        <div v-if="filteredContractors.length > 0">
            <b-card v-for="(contractor, index) in filteredContractors" :key="index" no-body>
                <div class="px-1 pt-1">
                    <div class="dotted-gradient pb-1">
                        <h5>{{ index + 1 }}) {{ contractor.name }} <em class="text-info text-italic">{{
                                contractor.inn
                            }}</em></h5>
                    </div>
                    <div class="d-flex justify-content-between pt-1">
                        <div>{{ translate.translate('key', 'tekushiyDolg') }}: </div>
                        <div v-if="contractor.debts.length > 0">
                            <div v-for="debt in contractor.debts">
                                <h6 class="text-right">
                                    <span class="text-warning">{{ debt.amount | decimal }}</span>
                                    {{ debt.currency.name }}
                                </h6>
                            </div>
                        </div>
                        <div v-else>
                            <h6 class="text-right"><span class="text-warning" style="margin-right: 5px">0</span>
                                {{ translate.translate('key', 'som') }}
                            </h6>
                        </div>
                    </div>
                    <div class="my-1">
                        <div v-for="contact in contractor.contacts" class="d-flex justify-content-between pb-1">
                            <div v-if="contact.type === 1" style="margin-right: 5px">Тел:</div>
                            <div v-if="contact.type === 3" style="margin-right: 5px">Email:</div>
                            <h6 class="text-info">{{ contact.value }}</h6>
                        </div>
                        <div class="d-flex justify-content-between pb-1" v-if="contractor.contacts.length === 0">
                            <div style="margin-right: 5px">Контакты:</div>
                            <h6 style="margin-right: 5px">не указано</h6>
                        </div>
                    </div>
                </div>

                <div class="text-right w-100">
                    <b-button class="w-100" variant="outline-info" size="sm" :to="{ name: 'contractor-view', params: { id: contractor.id }}">
                        <feather-icon icon="MapPinIcon" class="mr-1"/>
                        Местоположение клиента
                    </b-button>
                </div>
            </b-card>
        </div>

        <h4 class="text-center" v-else>Клиент не найден</h4>

        <div class="text-center mb-4"
             v-if="!search && filteredContractors.length > 0 && this.contractors.length > this.page"
        >
            <b-button variant="primary" @click="loadMore">{{ translate.translate('key', 'pokazatYeshyo') }} 15
            </b-button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import translate from '@/translation/translate'
import { useDebounceFn } from '@vueuse/core'
import { transliterate } from 'transliteration'

export default {
    name: 'AllContractors',
    data() {
        return {
            translate,
            search: this.$route.query && this.$route.query.search ? this.$route.query.search : '',
            loading: false,
            filteredContractors: []
        }
    },
    computed: {
        ...mapGetters({
            contractors: 'getContractors',
            page: 'getPageContractors'
        }),
    },
    methods: {
        ...mapMutations(['setPageContractors']),
        searchDebounce: useDebounceFn(function () {
            if (this.search) {
                this.filteredContractors = this.searchStringInArray(this.search)
            } else {
                this.filteredContractors = []
                this.setPageContractors(15)
                this.filteredContractors = this.contractors.slice(0, this.page)
            }
        }, 1000),
        searchStringInArray(searchString) {
            return this.contractors.filter(contractor => {
                const searchQuery = searchString.trim()
                    .toLowerCase()
                const transliteratedQuery = transliterate(searchQuery)
                const filterNameParts = transliteratedQuery.replaceAll('  ', ' ')
                    .split(' ')

                const itemNameLower = contractor.name.trim()
                    .toLowerCase()
                const transliteratedItemName = transliterate(itemNameLower)

                return filterNameParts.length === filterNameParts.filter(fnp => transliteratedItemName.indexOf(fnp) > -1).length ||
                    searchString && contractor.inn?.toLowerCase()
                        .match(searchQuery)
            })
        },
        loadMore() {
            if (this.page > this.contractors.length) {
                return
            }
            this.setPageContractors(this.page + 15)
            this.filteredContractors = this.contractors.slice(0, this.page)
        },
    },
    mounted() {
        this.filteredContractors = this.contractors.slice(0, this.page)
    }
}
</script>

<style scoped>

</style>